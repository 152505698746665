<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Reserva - Nuevo</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>
               
                
                <b-col md="6">
                  <b-form-group>
                    <label>Cliente: <span @click="modalClients" class="text-info link">Nuevo</span></label>
                    <v-select placeholder="Seleccione un cliente" class="w-100" :filterable="false" label="full_name" v-model="client" @search="SearchClients" :options="clients"></v-select>
                    <small v-if="errors.id_client" class="form-text text-danger" >Selccione un cliente</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Nro Reserva :">
                    <b-form-input readonly type="text" v-model="reservation.code"></b-form-input>
                    <small v-if="errors.code"  class="form-text text-danger" >Ingrese una código</small>
                  </b-form-group>
                </b-col>


                <b-col md="2">
                  <b-form-group label="Fecha Reserva:">
                    <b-form-input type="date" ref="date" v-model="reservation.date"></b-form-input>
                    <small v-if="errors.date" class="form-text text-danger">Seleccione una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Nro de Personas:">
                    <b-form-input type="text" v-model="reservation.persons"></b-form-input>
                    <small v-if="errors.persons" class="form-text text-danger">Ingrese el nro de personas</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Mesa:">
                    <b-form-select v-model="reservation.id_table" :options="tables"></b-form-select>
                    <small v-if="errors.id_table" class="form-text text-danger">Seleccione una mesa</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Hora llegada:">
                    <b-form-input type="time" class="text-center" step="any" v-model="reservation.hour"></b-form-input>
                    <small v-if="errors.hour" class="form-text text-danger" >Ingrese una hora</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Caja:">
                    <b-form-input class="text-center" disabled v-model="reservation.box"></b-form-input>
                    <small v-if="errors.id_box" class="form-text text-danger">Seleccione una caja</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Medio de Pago: ">
                    <b-form-select  v-model="reservation.payment_method" :options="payment_method"></b-form-select>
                  </b-form-group>
                </b-col>

               

                <b-col md="2">
                  <b-form-group label="Moneda:">
                    <b-form-select ref="coin" v-model="reservation.coin" :options="coins"></b-form-select>
                    <small v-if="errors.coin" class="form-text text-danger" >Selccione un tipo de moneda</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Pagado:">
                    <b-form-input type="number" class="text-right" step="any" v-model="reservation.paid"></b-form-input>
                    <small v-if="errors.paid" class="form-text text-danger" >Ingrese un monto</small>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Observación:">
                    <b-form-textarea rows="6" type="text" v-model="reservation.observation"></b-form-textarea>
                  </b-form-group>
                </b-col>

                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control btn-primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <ModalClients />
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
// components
import ModalClients from '@/views/components/ModalClient'
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  components:{
      vSelect,
      ModalClients,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'Reservation',
      role: 2,
      reservation: {
          id_reservation:'',
          id_table:'',
          id_warehouse:'1',
          id_client:'',
          id_user:'',
          id_box:'',
          persons:'',
          code:'',
          box:'',
          date:moment(new Date()).local().format("YYYY-MM-DD"),
          hour:'',
          payment_method: '008',
          coin:'PEN',
          observation:'',
          total:(0).toFixed(2),
          paid:(0).toFixed(2),
          state:1,
      },

      clients: [],
      client:null,

      coins:[
        {value: "PEN", text : "Soles"},
        {value: "USD", text : "Dolares"},
        {value: "CLP", text : "Pesos Chilenos"},
      ],

      payment_method: [
          {value :"001", text :'DEPÓSITO EN CUENTA'},
          {value :"003", text :'TRANSFERENCIA DE FONDOS'},
          {value :"004", text :'ORDEN DE PAGO'},
          {value :"005", text :'TARJETA DE DÉBITO'},
          {value :"006", text :'TARJETA DE CRÉDITO'},
          {value :"007", text :'CHEQUES CON LA CLÁUSULA DE "NO NEGOCIABLE"'},
          {value :"008", text :'EFECTIVO'},
          {value :"101", text :'TRANSFERENCIAS - COMERCIO EXTERIOR'},
          {value :"102", text :'CHEQUES BANCARIOS  - COMERCIO EXTERIOR'},
      ],
      tables:[],
      //errors
      errors: {
        id_client: false,
        code: false,
        id_box: false,
        number: false,
        broadcast_date: false,
        coin: false,
        total: false,
        persons: false,
        hour:false,
      },
      validate: false,
    };
  },
  mounted() {
    this.GetBoxByUser();
    this.GetCorrelative();
    this.ListTables();
  },
  methods: {
    GetBoxByUser,
    SearchClients,
    modalClients,
    ListTables,

    AddReservation,
    Validate,
    GetCorrelative,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};
function GetCorrelative() {
  let me = this;
  let url = this.url_base + "get-correlative/Reservation";
  axios({
    method: "GET",
    url: url,
    headers: {token: this.token,module: this.module,role: this.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.reservation.code = "RV-"+response.data.result.number;
    } 
  })
}

function ListTables() {
  let me = this;
  let url = this.url_base + "table/list-tables-select";
  axios({
    method: "GET",
    url: url,
    headers: {token: this.token,module: this.module,role: this.role},
  })
  .then(function (response) {
    me.tables = [{value:'',text:'Seleccione una mesa'}];
    if (response.data.status == 200) {
      for (let index = 0; index < response.data.result.length; index++) {
        const element = response.data.result[index];
        me.tables.push({value:element.id_table,text:element.room_name+" | "+ element.name})
      }
     
    } 
  })
}



function SearchClients(search, loading) {
   let me = this;
    let url = this.url_base + "search-clients/" + search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.clients = response.data;
            loading(false);
      })
    }
}

function GetBoxByUser() {
  let me = this;
  let url = me.url_base + "box/get-box-by-user/"+me.user.id_user;
  axios({
    method: "GET",
    url:url,
    headers: { token: me.token, module: me.module, role: me.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.reservation.box = response.data.result.code + " | " + response.data.result.user;
      me.reservation.id_box = response.data.result.id_box;
    } else {
      me.reservation.id_box = '';
      me.reservation.box = '';
    }
  })
  .catch((error) => {
  });
}


function modalClients() {
  EventBus.$emit('ModalClientsShow');
}

function AddReservation() {
  let me = this;
  me.reservation.id_user = me.user.id_user;
  me.reservation.id_client = me.client.id;
  let url = me.url_base + "reservation/add";
  let data = me.reservation;
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: 2, },
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.reservation.id_reservation='',
        me.reservation.id_table='',
        me.reservation.id_warehouse='1',
        me.reservation.id_client='',
        me.reservation.id_user='',
        me.reservation.id_box='',
        me.reservation.persons='',
        me.reservation.code='',
        me.reservation.box='',
        me.reservation.date=moment(new Date()).local().format("YYYY-MM-DD"),
        me.reservation.hour='',
        me.reservation.payment_method= '008',
        me.reservation.coin='PEN',
        me.reservation.observation='',
        me.reservation.total=(0).toFixed(2),
        me.reservation.paid=(0).toFixed(2),
        me.reservation.state=1,

        me.client = null;
        me.GetBoxByUser();
        me.GetCorrelative();
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {

  this.errors.id_client = this.client == null || this.client == {}  ? true : false;
  this.errors.id_table = this.reservation.id_table.length == 0 ? true : false;
  this.errors.code = this.reservation.code.length == 0 ? true : false;
  this.errors.date = this.reservation.date.length == 0 ? true : false;
  this.errors.hour = this.reservation.hour.length == 0 ? true : false;
  this.errors.persons = this.reservation.persons.length == 0 ? true : false;
  this.errors.id_box = this.reservation.id_box.length == 0 ? true : false;
  this.errors.coin = this.reservation.coin.length == 0 ? true : false;
  this.errors.total = this.reservation.total.length == 0 ? true : false;
  this.errors.paid = this.reservation.paid.length == 0 ? true : false;
  
  if (this.errors.id_client) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.id_table) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.code) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.hour) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.persons) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.id_box) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.coin) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.paid) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }


  let me = this;
  Swal.fire({
    title: "Esta seguro de registrar la reserva ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.AddReservation();
    }
  });
}
</script>
